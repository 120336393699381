<script>
import DefaultMixin from '../mixin'
import {find, registrarParceiro, update} from "@/domain/parceiros/services"
import mock from "@/domain/parceiros/helpers/mock"
import {UFakeLoader, UCheckbox} from "uloc-vue"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import AcaoParceiro from './buttons/Acao'
import GlobalPessoaInput from "components/pessoa/components/include/pessoa/PessoaInput"
import {EBtnTableOptions} from "@/plugins/uloc-erp"
import MenuOptions from "components/layout/context-menu/context-window-options"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"

export default {
  name: 'Parceiro',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin, GlobalPersonMixin],
  components: {
    ECol,
    ERow,
    UFakeLoader,
    UCheckbox,
    AcaoParceiro,
    GlobalPessoaInput,
    EBtnTableOptions,
    MenuOptions,
    MenuOptionsItem
  },
  data() {
    return {
      loading: this.$route.params.id,
      model: JSON.parse(JSON.stringify(mock)),
      editing: !this.$route.params.id
    }
  },
  computed: {},
  mounted() {
    this.$route.params.id && this.load(this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    load(id) {
      this.loading = true
      find(id)
          .then(response => {
            console.log(response)
            this.loading = false
            const data = response.data
            console.log(data)
            if (!data.config) {
              data.config = {}
            } else {
            }
            this.model = response.data
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    salvar() {
      this.$uloc.loading.show('Salvando')
      const model = JSON.parse(JSON.stringify(this.model))
      model.pessoa = model.pessoa.id || null
      model.config = JSON.stringify(model.config)
      const m = model.id ? update : registrarParceiro
      m(model, 'PATCH')
          .then(response => {
            this.$uloc.loading.hide()
            this.dg()
            this.editing = false
            console.log(response.data)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Parceiro',
    name: 'Parceiro'
  }
}
</script>

<template>
  <div class="parceiro-container wrapper-md">
    <div style="max-width: 1000px; margin: 0 auto 10px">
      <a @click="$router.go(-1)">Voltar</a>
    </div>
    <div class="sl-layout-page">
      <div v-if="!model.active"
           class="u-alert-default u-alert-content wrapper-lg m-b no-select">
        Parceiro inativo.
      </div>
      <div class="sl-layout-page-actions">
        <acao-parceiro :parceiro="model" @atualizaBin="load($route.params.id)"/>
      </div>
      <div v-if="loading">
        <u-fake-loader width="90%"/>
        <br>
        <u-fake-loader width="30%"/>
        <u-fake-loader width="50%"/>
        <u-fake-loader width="40%"/>
        <br><br>
        <u-fake-loader width="20%"/>
        <u-fake-loader width="40%"/>
        <br>
        <br>
        <br>
        <br>
        <br>
        <u-fake-loader width="20%"/>
      </div>
      <div v-else>
        <h2 class="diviser">Dados da Parceiro</h2>
        <div class="m-t">
          <e-row class="erp-row-view" mr>
            <e-col v-if="model.id" style="min-width: 50px">
              <label>ID</label>
              <p>{{ model.id }}</p>
            </e-col>
            <e-col v-if="model.id">
              <label>Registro</label>
              <p class="text-nowrap">{{ model.createdAt|formatDate }}</p>
            </e-col>
            <e-col v-if="model.id && model.dateModified">
              <label>Última Modificação</label>
              <p class="text-nowrap">{{ model.dateModified|formatDate }}</p>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <label>Pessoa</label>
              <p v-if="editing">
                <global-pessoa-input class="m-t-xs" v-model="model.pessoa"/>
              </p>
              <p v-else class="text-nowrap cursor-pointer"
                 @click="gotoPerson(model.pessoa)">{{ model.pessoa.name }} <i
                  class="fa fa-link font-10"></i></p>
            </e-col>
            <e-col>
              <label>Comissão</label>
              <p v-if="editing">
                <u-checkbox class="m-t-xs" v-model="model.comissao"/>
              </p>
              <p v-else>{{ model.comissao ? 'Sim' : 'Não' }}</p>
            </e-col>
          </e-row>
          <!--          <e-row class="erp-row-view" mr>
                      <e-col style="flex: 1;">
                        <label>Observações Internas</label>
                        <p v-if="!editing">{{ parceiro.observacaoInterna || '-' }}</p>
                        <p v-else><textarea class="sl-textarea full-width" rows="6"
                                            v-model="parceiro.observacaoInterna"></textarea></p>
                      </e-col>
                    </e-row>-->
        </div>

        <table class="sl-table-config">
          <thead>
          <th>Login</th>
          <th>Último acesso</th>
          <th class="text-center">Tipo Acesso</th>
          <th class="text-center">Acesso API <i class="fal fa-info-circle">
            <u-tooltip :offset="[10,10]">...</u-tooltip>
          </i></th>
          <th class="text-center">Acesso ERP <i class="fal fa-info-circle">
            <u-tooltip :offset="[10,10]">...</u-tooltip>
          </i></th>
          <th class="text-center">Painel Parceiro <i class="fal fa-info-circle">
            <u-tooltip :offset="[10,10]">...</u-tooltip>
          </i></th>
          <th class="text-center">Status</th>
          <th class="text-center"></th>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td>
              <u-fake-loader width="100%"/>
            </td>
            <td></td>
            <td>
              <u-fake-loader width="100%"/>
            </td>
            <td>
              <u-fake-loader width="100%"/>
            </td>
            <td>
              <u-fake-loader width="100%"/>
            </td>
          </tr>
          </tbody>
          <tr>
            <td style="height: 50px; min-width: 180px">LOGINTESTE</td>
            <td>-</td>
            <td class="text-center">Limitado</td>
            <td class="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="17"
                   viewBox="0 0 16 17">
                <image id="Imagem_50" data-name="Imagem 50" width="16" height="17"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABQmlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxiDFIMRgySCWmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsgslf74DR2bZtj9meX1JPjbtTWY6lEAV0pqcTKQ/gPESckFRSUMDIwJQLZyeUkBiN0CZIsUAR0FZM8AsdMhbJC5IkkQ9gGwmpAgZyD7CpAtkJyRmAJkPwGydZKQxNOR2FB7QYAj2Mjc1dTQgIBTSQclqRUlINo5v6CyKDM9o0TBERhCqQqeecl6OgpGBkZGDAyg8Iao/nwDHI6MYhwIsdQdDAwmzUDBmwix7HcMDHsWMTDwvUOIqeoD+bcZGA6lFSQWJcIdwPiNpTjN2AjC5t7OwMA67f//z+EMDOyaDAx/r////3v7//9/lzEwMN9iYDjwDQBW5WGWUflcbwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEKADAAQAAAABAAAAEQAAAAAq/Xp4AAAAmUlEQVQ4EWP8DwQMFAAmCvSCtQ4xA/YdOsYgoaTP4BUUC/c50V4AaY5KyIRrhDNAsUAI7D149L+4oh4YewbGoChnQOFh4eDTDFION6Chrf8/CCMDQppRDIA5EWYIMZpRDABphBkC8icyG9lV6Gy4F0ASyIaADEAPMHTNID6KASABmCEwr4DE8AFGkCQ8TslgEJ2QcJk98AYAAD36g9yhConAAAAAAElFTkSuQmCC"/>
              </svg>
            <td class="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="17"
                   viewBox="0 0 16 17">
                <image id="Imagem_50" data-name="Imagem 50" width="16" height="17"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABQmlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxiDFIMRgySCWmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsgslf74DR2bZtj9meX1JPjbtTWY6lEAV0pqcTKQ/gPESckFRSUMDIwJQLZyeUkBiN0CZIsUAR0FZM8AsdMhbJC5IkkQ9gGwmpAgZyD7CpAtkJyRmAJkPwGydZKQxNOR2FB7QYAj2Mjc1dTQgIBTSQclqRUlINo5v6CyKDM9o0TBERhCqQqeecl6OgpGBkZGDAyg8Iao/nwDHI6MYhwIsdQdDAwmzUDBmwix7HcMDHsWMTDwvUOIqeoD+bcZGA6lFSQWJcIdwPiNpTjN2AjC5t7OwMA67f//z+EMDOyaDAx/r////3v7//9/lzEwMN9iYDjwDQBW5WGWUflcbwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEKADAAQAAAABAAAAEQAAAAAq/Xp4AAAAmUlEQVQ4EWP8DwQMFAAmCvSCtQ4xA/YdOsYgoaTP4BUUC/c50V4AaY5KyIRrhDNAsUAI7D149L+4oh4YewbGoChnQOFh4eDTDFION6Chrf8/CCMDQppRDIA5EWYIMZpRDABphBkC8icyG9lV6Gy4F0ASyIaADEAPMHTNID6KASABmCEwr4DE8AFGkCQ8TslgEJ2QcJk98AYAAD36g9yhConAAAAAAElFTkSuQmCC"/>
              </svg>
            <td class="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="17"
                   viewBox="0 0 16 17">
                <image id="Imagem_50" data-name="Imagem 50" width="16" height="17"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABQmlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxiDFIMRgySCWmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsgslf74DR2bZtj9meX1JPjbtTWY6lEAV0pqcTKQ/gPESckFRSUMDIwJQLZyeUkBiN0CZIsUAR0FZM8AsdMhbJC5IkkQ9gGwmpAgZyD7CpAtkJyRmAJkPwGydZKQxNOR2FB7QYAj2Mjc1dTQgIBTSQclqRUlINo5v6CyKDM9o0TBERhCqQqeecl6OgpGBkZGDAyg8Iao/nwDHI6MYhwIsdQdDAwmzUDBmwix7HcMDHsWMTDwvUOIqeoD+bcZGA6lFSQWJcIdwPiNpTjN2AjC5t7OwMA67f//z+EMDOyaDAx/r////3v7//9/lzEwMN9iYDjwDQBW5WGWUflcbwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEKADAAQAAAABAAAAEQAAAAAq/Xp4AAAAmUlEQVQ4EWP8DwQMFAAmCvSCtQ4xA/YdOsYgoaTP4BUUC/c50V4AaY5KyIRrhDNAsUAI7D149L+4oh4YewbGoChnQOFh4eDTDFION6Chrf8/CCMDQppRDIA5EWYIMZpRDABphBkC8icyG9lV6Gy4F0ASyIaADEAPMHTNID6KASABmCEwr4DE8AFGkCQ8TslgEJ2QcJk98AYAAD36g9yhConAAAAAAElFTkSuQmCC"/>
              </svg>
            </td>
            <td class="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <rect id="Retângulo_1011" data-name="Retângulo 1011" width="12" height="12" rx="6"
                      :fill="model.active ? '#3baf55' : '#EB0000'"/>
              </svg>
            </td>
            <td>
              <e-btn-table-options>
                <menu-options>
                  <ul>
                    <menu-options-item close label="Abrir" @click="abrir(model)"/>
                    <menu-options-item close label="Excluir" @click="excluir(model)" label-class="text-negative"/>
                  </ul>
                </menu-options>
              </e-btn-table-options>
            </td>
          </tr>
        </table>

        <div class="sl-layout-page-footer-btns">
          <u-btn @click="salvar" v-if="editing" class="m-t-lg sl-btn positive" no-caps label="Salvar"/>
        </div>

      </div>
    </div>
  </div>
</template>
