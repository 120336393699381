<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {update} from "@/domain/parceiros/services"

export default {
  name: 'AcaoParceiro',
  inject: ['container'],
  props: ['parceiro'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
    editar () {
      this.container.editing = !this.container.editing
    },
    ativarCancelar(active = false) {
      this.$uloc.dialog({
        title: !active ? 'Você está prestes a inativar um parceiro' : 'Você está prestes a ativar um parceiro',
        message: !active ? 'Você tem certeza que deseja inativar este parceiro ?' : 'Você tem certeza que deseja reativar este parceiro ?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Atualizando'})
        const parceiro = {
          id: this.container.model.id,
          active: active
        }
        update(parceiro, 'PATCH')
            .then(response => {
              this.$uloc.loading.hide()
              this.dg(!active ? 'Parceiro desativado.' : 'Parceiro reativado com sucesso')
              this.container.model = response.data
              this.container.editing = false
              console.log(response.data)
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
      .catch(() => {})
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Ações">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul v-if="container.model.id">
          <menu-options-item @click="editar" :label="container.editing ? 'Cancelar edição' : 'Editar Parceiro'" close  />
        </ul>
<!--        <ul>
          <menu-options-item label="Imprimir" close  />
        </ul>--> <!-- @TODO -->
        <ul v-if="container.model.id">
          <menu-options-item v-if="container.model.active" @click="ativarCancelar(false)" label-class="text-negative" label="Desativar parceiro" close  /> <!-- @TODO -->
          <menu-options-item v-else @click="ativarCancelar(true)" label-class="text-positive" label="Reativar parceiro" close  /> <!-- @TODO -->
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
